import React from "react"
// import { Link } from "gatsby"

import Header from "../partials/header"
import Footer from "../partials/footer"
import Scroller from "../scrollbar"
// CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "../../utils/css/components/scrolls.css"
class Layout extends React.Component {
  render() {
    const { children, headerActive, location } = this.props
    return (
      <div id="site-wrapper" className="site-wrapper">
        <Scroller>
          <Header headerActive={headerActive} location={location}></Header>
          <main id="site-main">
            <div id="swup">{children}</div>
          </main>
          <div className="footer-expertise">
            <Footer></Footer>
          </div>
        </Scroller>
      </div>
    )
  }
}

export default Layout
